<template>
  <div>
    <div class="bg" />
    <div class="container">
      <div class="bar">
        <div class="title">绑定管理</div>
        <div class="rb1" @click="onDxBind()">
          <img src="@/assets/add.png" class="icon" />
          <div class="text">绑定</div>
        </div>
        <div class="rb" @click="goback">
          <img src="@/assets/fh.png" class="icon" />
          <div class="text">返回</div>
        </div> 
        
      </div>
      <div class="content">
        <div class="items">
          <div class="empty" v-if="items.length === 0">
            <div>
              <img src="@/assets/empty.png" />
            </div>
            <div class="text">当前无信息</div>
          </div>
          <div class="item" v-for="item in items" :key="item.id">
            <div class="box" @click="onDx(item)">
              <div class="name">
                <img src="@/assets/dx1.png" v-if="item.flag" class="dxicon" />
                <img src="@/assets/dx2.png" v-if="item.flag === false" class="dxicon" />
                {{ item.zone_name }}
              </div>
              <div class="unames">吧台：{{ item.client_name }}</div>
            </div>
            <!--<div class="box">
              <div class="view active" @click="onBind(item)">绑定</div>
            </div>-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { get, map } from 'lodash-es';
import { Dialog, Toast } from 'vant';
import { isEmpty, isTel } from '@/utils';
import qs from 'qs';
export default {
  created() {
    this.type = this.$route.query.type;
    this.unionid = this.$route.query.unionid;
    this.srole = this.$route.query.srole;
    this.query();
  },
  data() {
    return {
      type: '',
      unionid: '',
      srole: true,
      zonecodelist: '',
      zonenamelist: '',
      items: [
        /*{zone_name: '区域一', client_name: '吧台一', flag: false},
        {zone_name: '区域二', client_name: '吧台一', flag: true},
        {zone_name: '区域三', client_name: '吧台二', flag: false},*/
      ]
    };
  },
  methods: {
    goback() {
      this.$router.replace({path:'/admin/admin_index', query:{type:this.type,unionid:this.unionid,srole:this.srole}});
    },
    async query() {
      let { data } = await this.axios.get('/ctid/TldjService/dj_clientzonelist');
      //this.items = data.data;
      var test = data.data.replace(/-/g, "+").replace(/_/g, "/");
      this.items = JSON.parse(decodeURIComponent(escape(window.atob(test.split('.')[1])))).data;
    },
    onDx(item) {
      item.flag=!item.flag;
    },    
    async onBind(item) {
      /*let { data } = await this.axios.post(
        '/ctid/tlService/deletedoor', 
      qs.stringify({ 
        client_id: item.client_id 
      }));
      if (data.flag === '0') {
        Toast('删除成功!');
        this.query();
      } else {
        Dialog({ message: data.message });
      }*/
    },
    async onDxBind() {
      this.zonecodelist = '';
      this.zonenamelist = '';
      var f = true;
      for(var j = 0; j < this.items.length; j++) {
        if(this.items[j].flag){
          if(f){
            this.zonecodelist = this.items[j].zone_id;
            this.zonenamelist = this.items[j].zone_name;
            //alert(this.zonecodelist +','+ this.zonenamelist);
          }else{
            this.zonecodelist = this.zonecodelist + ',' + this.items[j].zone_id;
            this.zonenamelist = this.zonenamelist + ',' + this.items[j].zone_name;
            //alert(this.zonecodelist +','+ this.zonenamelist);
          }
          f = false;      
        }
      }
      
      if (isEmpty(this.zonecodelist)) {
        Toast('请选择区域!');
        return;
      }
      this.$router.replace({path:'/boss/bind_add', query:{
        zonecodelist: this.zonecodelist,
        zonenamelist: this.zonenamelist,
        type:this.type,
        unionid:this.unionid,
        srole:this.srole
      }});
    }    
  }
};
</script>

<style lang="less" scoped>
.bar {
  margin: 48px 40px;
  overflow: hidden;
  .title {
    line-height: 60px;
    color: #fff;
    font-size: 36px;
    float: left;
  }
  
  
  .rb {
    height: 64px;
    width: 146px;
    float: right;
    overflow: hidden;
    background: #a46fe6;
    border-radius: 38px;
    margin-right: 8px;
    .icon {
      width: 39px;
      height: 39px;
      margin: 13px;
      display: block;
      float: left;
    }
    
    .text {
      float: left;
      color: #fff;
      font-size: 30px;
      line-height: 64px;
    }
  }
  .rb1 {
    height: 64px;
    width: 146px;
    float: right;
    overflow: hidden;
    background: #a46fe6;
    border-radius: 38px;
    .icon {
      width: 39px;
      height: 39px;
      margin: 13px;
      display: block;
      float: left;
    }
    
    .text {
      float: left;
      color: #fff;
      font-size: 30px;
      line-height: 64px;
    }
  }
}

.rb2 {
    height: 80px;
    width: 80px;
    overflow: hidden;
    float: right;
    .icon1 {
      width: 50px;
      height: 50px;
      margin: 8px;
      display: block;

    }
  }
.content {
  padding: 0px;
  margin-top: 40px;
}

.xg {
  width: 660px;
  overflow: hidden;
  margin: 0px auto;
  padding: 30px 0px 20px 0px;
}

.items {
  overflow: hidden;
  padding-bottom: 32px;
  min-height: 616px;
  .item {
    margin: 0px 26px;
    padding: 24px 14px 22px;
    overflow: hidden;
    display: flex;
    border-bottom: 2px solid #eee;
  }
  .box {
    flex: 1;
    overflow: hidden;
    .name {
      line-height: 50px;
      overflow: hidden;
      font-size: 32px;
      .dxicon {
        width: 35px;
        height: 35px;
        margin-right: 10px;
        vertical-align: middle;
        margin-top: -6px;
      }
    }
    .unames {
      line-height: 40px;
      font-size: 28px;
      margin-top: 8px;
      color: #918e8e;
    }
    .del {
      width: 64px;
      height: 64px;
      display: block;
      float: right;
      margin-top: 18px;
    }
    .view {
      margin-top: 18px;
      width: 100px;
      height: 64px;
      border: 2px solid rgba(0, 29, 255, 1);
      border-radius: 44px;
      text-align: center;
      line-height: 64px;
      color: rgba(0, 29, 255, 1);
      font-size: 28px;
      float: right;
      margin-right: 30px;
    }
  }
}
.empty {
  text-align: center;
  margin-top: 212px;
  img {
    width: 150px;
  }
  .text {
    line-height: 40px;
    font-size: 28px;
    margin-top: 20px;
  }
}
</style>
